import { useForm, FormProvider } from "react-hook-form-new";
import { useDispatch } from "react-redux";

import Input from "../Input/v2/Input";
import FileInput from "../Input/v2/FileInput";

import { addForm, updateForm } from "../../actions/adminActions";

export default function FormularForm(props) {
  const dispatch = useDispatch();
  const { defaultValues, edit } = props;
  const methods = useForm({
    defaultValues: defaultValues,
  });

  const onSubmit = async (data) => {
    const formData = new FormData();

    if (data.file) {
      formData.append("file", data.file[0]);
    }
    formData.append("name", data.name);
    formData.append("description", data.description);

    edit
      ? dispatch(addForm(formData, props.history))
      : dispatch(updateForm(props.match.params.id, formData, props.history));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="font-display uppercase text-lg leading-6 font-medium text-gray-900">
            {edit ? "Formular hinzufügen" : "Formular bearbeiten"}
          </h3>
        </div>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <FileInput
            name="file"
            file={defaultValues.files}
            accept="image/*, application/pdf"
            required
          />

          <Input label="Name" name="name" />
          <Input label="Beschreibung" name="description" />
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              href="/admin/formulare"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Zurück
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Speichern
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
