import { useForm, FormProvider } from "react-hook-form-new";
import { useDispatch } from "react-redux";

import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import Input from "../Input/v2/Input";
import Select from "../Input/v2/Select";
import SwitchInput from "../Input/v2/SwitchInput";
import Spinner from "../Spinner";

import { addSalesman, updateSalesman } from "../../actions/adminActions";

export default function EinkaufForm(props) {
  const { edit, defaultValues } = props;
  const { data, error } = useSWR("/api/konto", fetcher);
  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const onSubmit = (data) =>
    edit
      ? dispatch(addSalesman(data, props.history))
      : dispatch(updateSalesman(props.match.params.id, data, props.history));

  if (error) return <div>failed to load</div>;
  if (!data) return <Spinner />;

  const kontenOptions = data.data.map((konto) => ({
    value: konto.id,
    label: konto.firstName + " " + konto.lastName,
  }));

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="font-display uppercase text-lg leading-6 font-medium text-gray-900">
            {edit ? "Einkäufer hinzufügen" : "Einkäufer bearbeiten"}
          </h3>
        </div>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <Input type="number" label="ID" name="salesmanId" />
          <Input label="Name" name="name" />

          <Input type="email" label="E-Mail" name="email" />
          <Input type="tel" label="Telefon" name="phoneNumber" />
          <Input label="Fax" name="faxNumber" />
          <div className="mb-3" />
          <Select
            label="Zugriff"
            name="role"
            defaultValue={defaultValues.accountId}
            options={kontenOptions}
          />
          <div className="mt-6">
            <label className="mb-3 block text-sm font-medium text-gray-700">
              Ist Einkäufer?
            </label>
            <SwitchInput name="isPurchaser" option="Ja" />
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <a
              href="/admin/einkauf"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Zurück
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Speichern
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
