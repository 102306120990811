import { useState } from "react";
import { useFormContext } from "react-hook-form-new";
import { PencilIcon } from "@heroicons/react/solid";

// The following component is an example of your existing Input Component
export default function FileInput({
  name,
  label,
  required,
  file,
  accept,
  errors,
  ...rest
}) {
  const [data, editData] = useState(file);
  const { register } = useFormContext(); // retrieve all hook methods

  return data ? (
    <span>
      <b>{label ? label : "Datei: "}</b>
      {data.fileName}
      <button onClick={() => editData()} className="table-control">
        <PencilIcon
          className="block h-6 w-6 hover:text-primary"
          aria-hidden="true"
        />
      </button>
    </span>
  ) : (
    <div className="input-group">
      <input {...register(name, { required })} type="file" accept={accept} />
    </div>
  );
}
