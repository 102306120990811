import { useForm, FormProvider } from "react-hook-form-new";
import { useDispatch } from "react-redux";

import Input from "../Input/v2/Input";
import Textarea from "../Input/v2/TextArea";

import { updateMail } from "../../actions/adminActions";

export default function EmailForm(props) {
  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: props.defaultValues,
  });

  const onSubmit = (data) =>
    dispatch(updateMail(props.match.params.id, data, props.history));

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="font-display uppercase text-lg leading-6 font-medium text-gray-900">
            E-Mail bearbeiten
          </h3>
        </div>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div className="mt-4 mb-2">
            <Input name="name" label="Name" />
            <Input name="subject" label="Betreff" />
            <Textarea name="message" label="Nachricht" />
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              href="/admin/email"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Zurück
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Speichern
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
