import useSWR from "swr";

import { useForm, FormProvider } from "react-hook-form-new";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addAlpha, updateAlpha } from "../../actions/adminActions";

import fetcher from "../../lib/fetcher";

import Spinner from "../Spinner";
import Input from "../Input/v2/Input";
import Select from "../Input/v2/Select";

export default function MittelwerteForm(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { defaultValues, edit } = props;

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const { data, error } = useSWR("/api/admin/sorten", fetcher);
  if (error) return <div>failed to load</div>;
  if (!data) return <Spinner />;

  const sortenOptions = data.data.map((sorten) => ({
    value: sorten.Code,
    label: sorten.Beschreibung,
  }));

  const alphaSourceOptions = [
    {
      value: "AHA (Arbeitsgruppe Hopfenanalyse)",
      label: "AHA (Arbeitsgruppe Hopfenanalyse)",
    },
    {
      value: "AHA (Arbeitsgruppe Hopfenanalyse) Hallertau",
      label: "AHA (Arbeitsgruppe Hopfenanalyse) Hallertau",
    },
    {
      value: "Durchschnitt Steiner",
      label: "Durchschnitt Steiner",
    },
  ];

  const qualityOptions = [
    {
      value: "NQF (Neutrale Qualitätsfeststellung)",
      label: "NQF (Neutrale Qualitätsfeststellung)",
    },
    { value: "Durchschnitt Steiner", label: "Durchschnitt Steiner" },
  ];

  const onSubmit = (data) =>
    edit
      ? dispatch(addAlpha(data, history))
      : dispatch(updateAlpha(props.match.params.id, data, history));

  const areaOptions = [
    { value: "DEE", label: "Elbe Saale" },
    { value: "DEH", label: "Hallertau" },
    { value: "DES", label: "Spalt" },
    { value: "DET", label: "Tettnang" },
  ];
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="font-display uppercase text-lg leading-6 font-medium text-gray-900">
            {edit ? "Mittelwert hinzufügen" : "Mittelwert bearbeiten"}
          </h3>
        </div>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <Input name="cropYear" label="Erntejahr" />
          <br />
          <Select
            label="Anbaugebiet"
            name="growingArea"
            defaultValue={defaultValues.growingArea}
            options={areaOptions}
          />
          <br />
          <Select
            label="Sorte"
            name="variety"
            defaultValue={defaultValues.variety}
            options={sortenOptions}
          />

          <div className="mt-4 mb-2">
            <Input name="alphaContent" label="Alphagehalt" />

            <Select
              name="alphaContentSourceId"
              defaultValue={defaultValues.alphaContentSourceId}
              options={alphaSourceOptions}
            />
          </div>
          <div className="mt-4 mb-2">
            <Input name="humidity" label="Wasser (%)" />
            <Select
              name="humiditySourceId"
              defaultValue={defaultValues.humiditySourceId}
              options={qualityOptions}
            />
          </div>
          <div className="mt-4 mb-2">
            <Input name="leafStemPercent" label="Blatt / Stengel (%)" />
            <Select
              name="leafStemPercentSourceId"
              defaultValue={defaultValues.leafStemPercentSourceId}
              options={qualityOptions}
            />
          </div>
          <div className="mt-4 mb-2">
            <Input name="wastePercent" label="Abfall (%)" />
            <Select
              name="wastePercentSourceId"
              defaultValue={defaultValues.wastePercentSourceId}
              options={qualityOptions}
            />
          </div>
          <div className="mt-4 mb-2">
            <Input name="percentLeaves" label="Doldenblätter (%)" />
            <Select
              name="percentLeavesSourceId"
              defaultValue={defaultValues.percentLeavesSourceId}
              options={qualityOptions}
            />
          </div>
          <div className="mt-4 mb-2">
            <Input name="rating" label="Bonität" />
            <Select
              name="ratingSourceId"
              defaultValue={defaultValues.ratingSourceId}
              options={qualityOptions}
            />
          </div>
          <div className="mt-4 mb-2">
            <Input name="balance" label="Saldo" />
            <Select
              name="balanceSourceId"
              defaultValue={defaultValues.balanceSourceId}
              options={qualityOptions}
            />
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              href="/admin/mittelwerte"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Zurück
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Speichern
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
